<template>
  <log-table
    @refresh="getLogs"
    :environment="envName"
    :headers="headers"
    :logs="logs"
    :name="name"
    :version="version"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'coreLogs',
  components: {
    logTable: () => import('@/components/logTable.vue'),
  },
  props: {
    envName: {
      type: String,
      default: '',
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
    version: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Date',
        value: 'datetime',
      },
      {
        text: 'Correlation',
        value: 'correlation_uid',
      },
      {
        text: 'Severity',
        value: 'severity',
      },
      {
        text: 'Message',
        value: 'message',
      },
    ],
    logs: [],
  }),
  created() {
    this.getLogs();
  },
  methods: {
    ...mapActions({
      fetchGameOrCoreLogs: 'fetch/fetchGameOrCoreLogs',
    }),
    getLogs() {
      this.fetchGameOrCoreLogs({
        url: `/v2/log/core/${this.envName}`,
        params: {
          name: this.name,
          version: this.version,
        },
      }).then((result) => { this.logs = result; });
    },
  },
};
</script>
